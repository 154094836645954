import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const TermsAndConditions = ({ location }: { location: any }) => {
  useEffect(() => {
    navigate('/signup-form');
  }, []);

  return null;
};

export default TermsAndConditions;
